import Transaction from '@/models/Transaction'
import Api from '@/services/api'
import { each } from 'lodash'

export default {
  namespaced: true,
  state: {
    transactions: [],
  },

  mutations: {
    setTransactions(state, payload) {
      state.transactions = payload
    },

    setLoadMoreTransactions(state, payload) {
      each(payload.data, (transactions) => {
        state.transactions.data.push(new Transaction(transactions))
      })
      state.transactions.meta = payload.meta
      state.transactions.links = payload.links
    },

    clearTransactionList(state) {
      state.list = []
    },

    setTransactionListMeta(state, meta) {
      state.listMeta = meta
    },
  },

  actions: {
    async getTransactions({ commit }, params = { page: 1 }) {
      const query = Transaction.page(params.page ? params.page : 1)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params.transaction_type) {
        params['filter'] = { transaction_type: [params.transaction_type] }
      }

      if (params.loadMore) {
        delete params.loadMore
        const res = await query.params(params).get()
        commit('setLoadMoreTransactions', res)
      } else {
        const res = await query.params(params).get()
        commit('setTransactions', res)
      }
    },

    async exportToExcel({ commit }, params) {
      let transaction_type = params.transaction_type || ''
      let search = params.search || ''

      const file = await Api.get(
        `/export-transactions${`?filter[transaction_type]=${transaction_type}`}&filter[search]=${search}`,
        {
          responseType: 'blob',
        }
      )
      return file
    },
  },
}
