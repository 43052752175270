import EducationCategory from '@/models/EducationCategory'
import Api from '@/services/api'
import { each, find } from 'lodash'
import Form from '@/utils/form'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    categories: [],
    categoryForm: new Form({
      label: null,
      id: null,
      type: null,
      sub_categories: [],
      has_error: false,
    }),
    categoryLoading: false,
    editCategoryValue: new Form({
      label: null,
      id: null,
      type: null,
      sub_categories: [],
      has_error: false,
    }),
  },

  mutations: {
    setCategories(state, categories) {
      each(categories, (category) => {
        const exist = find(state.categories, { id: category.id })
        if (!exist) {
          state.categories.push(new EducationCategory(category))
        }
      })
    },
    updateEditCategory(state, category) {
      Object.keys(category).forEach(function (key) {
        if (state.editCategoryValue.hasOwnProperty(key)) {
          state.editCategoryValue[key] = category[key]
        }
      })

      state.editCategoryValue.sub_categories.map((item, key) => {
        const data = {
          label: null,
        }

        const lastEl = item.sub_sub_categories.length - 1
        if (
          item.sub_sub_categories[lastEl] &&
          item.sub_sub_categories[lastEl].label &&
          item.sub_sub_categories[lastEl].label !== ''
        ) {
          item.sub_sub_categories.push(data)
        }
      })
    },
    updateCategory(state, data) {
      const catIndex = state.categories.findIndex(
        (category) => category.id === data.data.id
      )
      Vue.set(state.categories, catIndex, data.data)
    },
    setEducationCategoryErrors(state, payload) {
      state.categoryForm.has_error = !!Object.keys(payload).length
      state.categoryForm.$setErrors(payload)
    },
    setEditCategoryErrors(state, payload) {
      state.editCategoryValue.has_error = !!Object.keys(payload).length
      state.editCategoryValue.$setErrors(payload)
    },
    setCategoryLoading(state, payload) {
      state.categoryLoading = payload
    },
    resetCategoryFormError(state) {
      state.categoryForm.$reset()
      state.editCategoryValue.$reset()
      state.categoryForm.has_error = false
      state.editCategoryValue.has_error = false
    },
    removeCategory(state, id) {
      const catIndex = state.categories.findIndex(
        (category) => category.id === id
      )
      if (catIndex > -1) {
        state.categories.splice(catIndex, 1)
      } else {
        state.editCategoryValue.sub_categories.map((subCategory, subKey) => {
          if (id === subCategory.id) {
            if (state.editCategoryValue.id) {
              state.editCategoryValue.sub_categories.splice(subKey, 1)
            }
          } else {
            subCategory.sub_sub_categories.map((subSubCategory, subSubKey) => {
              if (id === subSubCategory.id) {
                if (state.editCategoryValue.id) {
                  subCategory.sub_sub_categories.splice(subSubKey, 1)
                }
              }
            })
          }
        })
      }
    },
    addSubCategory(state) {
      const data = {
        label: null,
        sub_sub_categories: [
          {
            label: null,
          },
        ],
      }

      let form = state.categoryForm.sub_categories

      if (state.editCategoryValue.id) {
        form = state.editCategoryValue.sub_categories
      }

      form.push(data)
    },
    addSubSubCategory(state, subCategoryKey) {
      const data = {
        label: null,
      }

      let form = state.categoryForm.sub_categories[subCategoryKey]

      if (state.editCategoryValue.id) {
        form = state.editCategoryValue.sub_categories[subCategoryKey]
      }

      form.sub_sub_categories.push(data)
    },
    deleteSubSubCategory(state, subs) {
      let subCategory = state.categoryForm.sub_categories[subs.subKey]

      if (state.editCategoryValue.id) {
        subCategory = state.editCategoryValue.sub_categories[subs.subKey]
      }

      subCategory.sub_sub_categories.splice(subs.subSubKey, 1)
    },
    deleteSubCategory(state, subKey) {
      let subCategory = state.categoryForm.sub_categories

      if (state.editCategoryValue.id) {
        subCategory = state.editCategoryValue.sub_categories
      }

      subCategory.splice(subKey, 1)
    },
  },

  actions: {
    async getExercieCategories({ commit }, params = { page: 1, sort: 'id' }) {
      const query = EducationCategory.page(params.page || 1)
      if (params.search) {
        query.where('search', params.search)
      }
      const res = await query.params({ limit: 20 }).get()
      commit('setCategories', res.data)
    },

    async addEducationCategory({ commit }, params) {
      commit('setCategoryLoading', true)
      try {
        const data = await Api.post(`education/categories`, params)
        commit('setCategories', [data.data.data])
        commit('resetCategoryFormError')
      } catch ({ response: { data } }) {
        commit('setEducationCategoryErrors', data.errors)
      }
      commit('setCategoryLoading', false)
    },

    async editEducationCategory({ commit }, params) {
      commit('setCategoryLoading', true)
      try {
        const data = await Api.put(`education/categories/${params.id}`, params)
        commit('updateCategory', data.data)
        commit('resetCategoryFormError')
      } catch ({ response: { data } }) {
        commit('setEditCategoryErrors', data.errors)
      }
      commit('setCategoryLoading', false)
    },

    async removeEducationCategory({ commit }, params) {
      commit('setCategoryLoading', true)
      try {
        await Api.delete(`education/categories/${params.id}`, params)
        commit('removeCategory', params.id)
      } catch ({ response: { data } }) {
        commit(
          'setSnackbar',
          { show: true, text: `${data.message}`, color: 'red' },
          { root: true }
        )
      }
      commit('setCategoryLoading', false)
    },
  },
}
