import Form from '@/utils/form'
import Api from '@/services/api'
import Practitioner from '@/models/Practitioner'
import { each } from 'lodash'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    practitioners: { data: [] },
    practitioner: null,
    practitionerForm: new Form({
      name: null,
      introduction: null,
      avatar: [],
      introduction_video: [],
      gallery: [],
      specialisation: '',
    }),
    practitionerLoading: false,
    practitionerGalleryLoading: false,
  },

  mutations: {
    setPractitioners(state, payload) {
      state.practitioners = payload
    },
    setLoadMorePractitioners(state, payload) {
      each(payload.data, (practitioner) => {
        state.practitioners.data.push(new Practitioner(practitioner))
      })
      state.practitioners.meta = payload.meta
      state.practitioners.links = payload.links
    },
    setSelectedPractitioner(state, payload) {
      state.selectedPractitioners.push(payload)
    },
    setPractitionerErrors(state, payload) {
      if (payload.thumb_nail) {
        payload['photos'] = payload.thumb_nail
      }
      state.practitionerForm.$setErrors(payload)
    },
    setPractitionerLoading(state, payload) {
      state.practitionerLoading = payload
    },
    setPractitionerGalleryLoading(state, payload) {
      state.practitionerGalleryLoading = payload
    },
    clearPractitioners(state, payload) {
      state.practitioners = {
        data: [],
      }
    },
    resetPractitionerFormError(state) {
      state.practitionerForm.$reset()
    },
    clearFormErrors(state) {
      state.practitionerForm.$clearErrors()
    },
    setPractitionerItem(state, payload) {
      state.practitioner = payload
      Object.assign(state.practitionerForm, payload.data)
    },
    setPractitionerGallery(state, payload) {
      state.practitionerForm.gallery = payload.data
      // Object.assign(state.practitionerForm.gallery, payload.data)
    },
    updatePractitioner(state, payload) {
      Object.assign(state.practitionerForm, payload.data)
    },
    updatePractitionerGallery(state, payload) {
      state.practitionerForm.gallery.push(payload.data)
    },
    updateAltPractitioner(state, payload) {
      const altPractitionerIndex =
        state.practitioner.data.alternative_practitioners.findIndex(
          (altPractitioner) => altPractitioner.id === payload.data.id
        )
      Vue.set(
        state.practitioner.data.alternative_practitioners,
        altPractitionerIndex,
        payload.data
      )
    },
    removeAltPractitioner(state, payload) {
      const altPractitionerId =
        state.practitioner.data.alternative_practitioners.findIndex(
          (altPractitioner) => altPractitioner.id === payload.practitioner_id
        )

      if (altPractitionerId > -1) {
        state.practitioner.data.alternative_practitioners.splice(
          altPractitionerId,
          1
        )
      }
    },
    setAltPractitioner(state, payload) {
      state.practitioner.data.alternative_practitioners.push(payload.data)
    },
    deletePractitionerGallery(state, id) {
      const galleryId = state.practitionerForm.gallery.findIndex(
        (gallery) => gallery.id === id
      )

      if (galleryId > -1) {
        state.practitionerForm.gallery.splice(galleryId, 1)
      }
    },
  },

  actions: {
    async getPractitioners({ commit }, params = { page: 1 }) {
      commit('setPractitionerLoading', true)
      const query = Practitioner.page(params.page ? params.page : 1).include([
        'thumb_nail',
      ])
      if (params.search) {
        query.where('search', params.search)
      }

      if (params.category) {
        params['filter'] = { category: [params.category] }
      }

      if (params.loadMore) {
        delete params.loadMore
        const res = await query.params(params).get()
        commit('setLoadMorePractitioners', res)
      } else {
        const res = await query.params(params).get()
        commit('setPractitioners', res)
      }

      commit('setPractitionerLoading', false)
    },
    async getPractitionersGallery({ commit }, payload = {}) {
      try {
        const { data } = await Api.get(`practitioners/${payload.id}/gallery`)
        commit('setPractitionerGallery', data)
      } catch ({ response: { data } }) {}
    },
    async uploadPractitionerGallery({ commit }, params) {
      commit('setPractitionerGalleryLoading', true)
      try {
        const { data } = await Api.post(
          `practitioners/${params.id}/gallery`,
          params.data
        )
        commit('updatePractitionerGallery', data)
        commit('clearFormErrors')
      } catch ({ response: { data } }) {
        commit('setPractitionerErrors', data.errors)
      }
      commit('setPractitionerGalleryLoading', false)
    },
    async deletePractitionerGallery({ commit }, params) {
      commit('setPractitionerGalleryLoading', true)
      try {
        await Api.delete(
          `practitioners/${params.id}/gallery/${params.galleryId}`
        )
        commit('deletePractitionerGallery', params.galleryId)
      } catch (data) {}
      commit('setPractitionerGalleryLoading', false)
    },
    async getPractitioner({ commit }, payload = {}) {
      try {
        const { data } = await Api.get(`practitioners/${payload.id}`)
        commit('setPractitionerItem', data)
      } catch ({ response: { data } }) {}
    },
    async updatePractitioner({ commit }, params) {
      commit('setPractitionerLoading', true)
      try {
        const data = await Api.post(
          `practitioners/${params.id}?_method=put`,
          params.data
        )
        commit('updatePractitioner', data.data)
        commit('clearFormErrors')
        commit(
          'setSnackbar',
          { show: true, text: 'Successfully updated' },
          { root: true }
        )
      } catch ({ response: { data } }) {
        commit('setPractitionerErrors', data.errors)
      }
      commit('setPractitionerLoading', false)
    },
    async createPractitioner({ commit }, params) {
      commit('setPractitionerLoading', true)
      try {
        const data = await Api.post(`practitioners`, params)
        // commit('setCategories', [data.data.data])
        commit('resetPractitionerFormError')
        commit('setPractitionerLoading', false)
        commit(
          'setSnackbar',
          { show: true, text: 'Successfully created' },
          { root: true }
        )
        return data
      } catch ({ response: { data } }) {
        commit('setPractitionerErrors', data.errors)
      }
      commit('setPractitionerLoading', false)
    },
    async deletePractitioner({ commit }, id) {
      commit('setPractitionerLoading', true)
      try {
        await Api.delete(`practitioners/${id}`)
        commit(
          'setSnackbar',
          { show: true, text: 'Successfully deleted' },
          { root: true }
        )
      } catch (data) {}
      commit('setPractitionerLoading', false)
    },
  },
}
