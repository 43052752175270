import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'
import settings from './settings'
import support from './support'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    auth,
    support,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'users' },
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
      children: [
        {
          path: 'inbox',
          name: 'inbox',
          component: () =>
            import(/* webpackChunkName: "inbox" */ '@/views/Home/Inbox'),
        },
        {
          path: 'test',
          name: 'test',
          component: () =>
            import(/* webpackChunkName: "inbox" */ '@/views/Home/Test'),
        },
        {
          path: 'users',
          name: 'users',
          component: () =>
            import(/* webpackChunkName: "users" */ '@/views/Home/Users'),
        },
        {
          path: 'users/:id',
          name: 'user.details',
          component: () =>
            import(
              /* webpackChunkName: "users" */ '@/views/Home/Users/UserDetails'
            ),
        },
        {
          path: 'content',
          name: 'content',
          component: () =>
            import(/* webpackChunkName: "content" */ '@/views/Home/Content'),
        },
        {
          path: 'transactions',
          name: 'transactions',
          component: () =>
            import(
              /* webpackChunkName: "transaction" */ '@/views/Home/Transactions'
            ),
        },
        {
          path: 'subscriptions',
          name: 'subscriptions',
          component: () =>
            import(
              /* webpackChunkName: "subscription" */ '@/views/Home/Transactions/Subscriptions'
            ),
        },
        {
          path: 'exercise',
          name: 'exercise',
          component: () =>
            import(/* webpackChunkName: "exercise" */ '@/views/Home/Exercise'),
        },
        {
          path: 'exercise/create',
          name: 'exercise.create',
          component: () =>
            import(
              /* webpackChunkName: "exercise create" */ '@/views/Home/Exercise/Create'
            ),
        },
        {
          path: 'exercise/:id',
          name: 'exercise.item',
          component: () =>
            import(
              /* webpackChunkName: "users" */ '@/views/Home/Exercise/ExerciseItem'
            ),
        },
        {
          path: 'exercise-categories',
          name: 'exercise-categories',
          component: () =>
            import(
              /* webpackChunkName: "content" */ '@/views/Home/Exercise/ExerciseCategories'
            ),
        },
        {
          path: 'education',
          name: 'education',
          component: () =>
            import(
              /* webpackChunkName: "education" */ '@/views/Home/Education'
            ),
        },
        {
          path: 'education/create',
          name: 'education.create',
          component: () =>
            import(
              /* webpackChunkName: "exercise create" */ '@/views/Home/Education/Create'
            ),
        },
        {
          path: 'education/:id',
          name: 'education.item',
          component: () =>
            import(
              /* webpackChunkName: "users" */ '@/views/Home/Education/EducationItem'
            ),
        },
        {
          path: 'education-categories',
          name: 'education-categories',
          component: () =>
            import(
              /* webpackChunkName: "content" */ '@/views/Home/Education/EducationCategories'
            ),
        },
        {
          path: 'program',
          name: 'program',
          component: () =>
            import(/* webpackChunkName: "program" */ '@/views/Home/Program'),
        },
        {
          path: 'program/create',
          name: 'program.create',
          component: () =>
            import(
              /* webpackChunkName: "program create" */ '@/views/Home/Program/Create'
            ),
        },
        {
          path: 'program/:id',
          name: 'program.item',
          component: () =>
            import(
              /* webpackChunkName: "users" */ '@/views/Home/Program/ProgramItem'
            ),
        },
        {
          path: 'program-categories',
          name: 'program-categories',
          component: () =>
            import(
              /* webpackChunkName: "content" */ '@/views/Home/Program/ProgramCategories'
            ),
        },
        {
          path: 'practitioner',
          name: 'practitioner',
          component: () =>
            import(
              /* webpackChunkName: "program" */ '@/views/Home/Practitioner'
            ),
        },
        {
          path: 'practitioner/create',
          name: 'practitioner.create',
          component: () =>
            import(
              /* webpackChunkName: "practitioner create" */ '@/views/Home/Practitioner/Create'
            ),
        },
        {
          path: 'practitioner/:id',
          name: 'practitioner.item',
          component: () =>
            import(
              /* webpackChunkName: "users" */ '@/views/Home/Practitioner/PractitionerItem'
            ),
        },
        {
          path: 'on-boarding',
          name: 'on-boarding',
          component: () =>
            import(
              /* webpackChunkName: "on-boarding" */ '@/views/Home/OnBoarding'
            ),
        },
        {
          path: 'notification',
          name: 'notification',
          component: () =>
            import(
              /* webpackChunkName: "push-notification" */ '@/views/Home/Notification'
            ),
        },
        settings,
        {
          path: 'reports',
          name: 'reports',
          component: () =>
            import(/* webpackChunkName: "reports" */ '@/views/Home/Reports'),
        },
      ],
    },
  ],
})
