export default {
  path: '/support',
  name: 'support',
  redirect: { name: 'support.privacy' },
  component: () => import(/* webpackChunkName: "auth" */ '@/views/Support'),
  children: [
    {
      path: 'privacy',
      name: 'support.privacy',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/Support/Privacy'),
    },
    {
      path: 'terms-and-conditions',
      name: 'support.terms-and-conditions',
      component: () =>
        import(
          /* webpackChunkName: "auth" */ '@/views/Support/TermsAndConditions'
        ),
    },
  ],
}
