import Onboarding from '@/models/Onboarding'
import Api from '@/services/api'
import Form from '@/utils/form'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    onboardingOptions: {
      data: [],
      meta: [],
    },
    onboardingOptionsForm: new Form({
      title: null,
      fields: null,
    }),
    onboardingUpdateLoading: false,
    onboardingArchiveLoading: false,
    onboardingEditTitle: false,
    onboardingOptionLoading: false,
  },

  actions: {
    async getOnboardingOptions({ commit }, params = {}) {
      const query = Onboarding.page(params.page || 1).include('fields')
      const { data, meta } = await query.params({ limit: 20 }).get()
      commit('setOnboardingOptions', { data, meta })
    },

    async addOnboardingOptions({ commit }, params) {
      commit('setOnboardingOptionLoading', true)
      try {
        const data = await Api.post(`onboardingoptions`, params)
        commit('pushOnboardingOption', data.data)
        commit('resetOnboardingOptionsForm')
      } catch ({ response: { data } }) {
        commit('setOnboardingOptionsErrors', data.errors)
      }
      commit('setOnboardingOptionLoading', false)
    },

    async updateOnboardingOptions({ commit }, params) {
      commit('setOnboardingEditTitle', true)
      try {
        await Api.put(`onboardingoptions/${params.id}`, params)
        commit('resetOnboardingOptionsForm')
      } catch ({ response: { data } }) {}
      commit('setOnboardingEditTitle', false)
    },

    async updateOptionFields({ commit }, params) {
      commit('setOnboradingUpdateLoading', true)
      try {
        const { data } = await Api.put(
          `onboardingoptionfields/${params.field.id}`,
          params.field
        )
        commit('setOnBoardingField', { data, ...{ optionId: params.optionId } })
        commit('clearOnboardingOptionsErrors')
      } catch (data) {
        commit('setOnboardingOptionsErrors', data.errors)
      }
      commit('setOnboradingUpdateLoading', false)
    },

    async removeOptionFields({ commit }, params) {
      commit('setOnboardingArchiveLoading', true)
      try {
        await Api.delete(`onboardingoptionfields/${params.field.id}`)
        commit('removeOnboardingField', {
          ...{ data: params.field },
          ...{ optionId: params.optionId },
        })
      } catch (res) {}
      commit('setOnboardingArchiveLoading', false)
    },

    async archiveOnboardingOption({ commit }, params) {
      try {
        await Api.delete(`onboardingoptions/${params.id}`)
        commit('removeOnboardingOption', params)
      } catch (res) {}
    },
  },

  mutations: {
    setOnboardingOptions(state, onboardingOptions) {
      state.onboardingOptions.data = onboardingOptions.data
    },
    pushOnboardingOption(state, onboarding) {
      state.onboardingOptions.data.push(onboarding.data)
    },
    setOnboardingOptionsErrors(state, payload) {
      state.onboardingOptionsForm.$setErrors(payload)
    },
    clearOnboardingOptionsErrors(state, payload) {
      state.onboardingOptionsForm.$clearErrors()
    },
    resetOnboardingOptionsForm(state) {
      state.onboardingOptionsForm.$reset()
    },
    setOnboradingUpdateLoading(state, payload) {
      state.onboardingUpdateLoading = payload
    },
    setOnboardingArchiveLoading(state, payload) {
      state.onboardingArchiveLoading = payload
    },
    setOnboardingEditTitle(state, payload) {
      state.onboardingEditTitle = payload
    },
    setOnboardingOptionLoading(state, payload) {
      state.onboardingOptionLoading = payload
    },
    removeOnboardingOption(state, onboarding) {
      const index = state.onboardingOptions.data.findIndex(
        (option) => option.id === onboarding.id
      )

      if (index > -1) {
        state.onboardingOptions.data.splice(index, 1)
      }
    },
    removeOnboardingField(state, onboarding) {
      const index = state.onboardingOptions.data.findIndex(
        (option) => option.id === onboarding.optionId
      )

      const fieldIndex = state.onboardingOptions.data[index].fields.findIndex(
        (field) => field.id === onboarding.data.id
      )

      state.onboardingOptions.data[index].fields.splice(fieldIndex, 1)
    },
    setOnBoardingField(state, onboarding) {
      const index = state.onboardingOptions.data.findIndex(
        (option) => option.id === onboarding.optionId
      )

      const fieldIndex = state.onboardingOptions.data[index].fields.findIndex(
        (field) => field.id === onboarding.data.data.id
      )

      Vue.set(
        state.onboardingOptions.data[index].fields,
        fieldIndex,
        onboarding.data.data
      )
    },
  },
}
