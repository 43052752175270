import ProgramCategory from '@/models/ProgramCategory'
import Category from '@/models/Category'
import Api from '@/services/api'
import { each, find } from 'lodash'
import Form from '@/utils/form'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    categoryList: {
      loading: false,
      params: { page: 1, per_page: 10 },
      data: {
        data: [],
        meta: { total: 0 },
      },
    },
    categories: [],
    categoryForm: new Form({
      label: null,
      id: null,
      type: null,
    }),
    categoryLoading: false,
    editCategoryValue: new Form({
      label: null,
      id: null,
      type: null,
    }),
  },

  mutations: {
    setCategoryList(state, payload) {
      state.categoryList = payload
    },
    setCategories(state, categories) {
      each(categories, (category) => {
        const exist = find(state.categories, { id: category.id })
        if (!exist) {
          state.categories.push(new ProgramCategory(category))
        }
      })
    },
    updateEditCategory(state, category) {
      Object.keys(category).forEach(function (key) {
        if (state.editCategoryValue.hasOwnProperty(key)) {
          state.editCategoryValue[key] = category[key]
        }
      })
    },
    updateCategory(state, data) {
      const catIndex = state.categories.findIndex(
        (category) => category.id === data.data.id
      )
      Vue.set(state.categories, catIndex, data.data)
    },
    setProgramCategoryErrors(state, payload) {
      state.categoryForm.$setErrors(payload)
    },
    setEditCategoryErrors(state, payload) {
      state.editCategoryValue.$setErrors(payload)
    },
    setCategoryLoading(state, payload) {
      state.categoryLoading = payload
    },
    resetCategoryFormError(state) {
      state.categoryForm.$reset()
      state.editCategoryValue.$reset()
    },
    removeCategory(state, id) {
      const catIndex = state.categories.findIndex(
        (category) => category.id === id
      )

      if (catIndex > -1) {
        state.categories.splice(catIndex, 1)
      }
    },
  },

  actions: {
    async getProgramCategoryList({ commit, state }, params) {
      commit('setCategoryList', { ...state.categoryList, loading: true })

      const query = Category.where('type', 'program')
        .page(params?.page ?? 1)
        .limit(params?.per_page ?? 10)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params?.sort) {
        query.orderBy(params.sort)
      }

      const data = await query.get()

      commit('setCategoryList', { loading: false, params, data })
    },
    async getProgramCategories({ commit }, params = { page: 1, sort: 'id' }) {
      const query = ProgramCategory.page(params.page || 1)
      if (params.search) {
        query.where('search', params.search)
      }
      const res = await query.params({ limit: 20 }).get()
      commit('setCategories', res.data)
    },

    async addProgramCategory({ commit }, params) {
      commit('setCategoryLoading', true)
      try {
        const data = await Api.post(`program/categories`, params)
        commit('setCategories', [data.data.data])
        commit('resetCategoryFormError')
      } catch ({ response: { data } }) {
        commit('setProgramCategoryErrors', data.errors)
      }
      commit('setCategoryLoading', false)
    },

    async editProgramCategory({ commit }, params) {
      commit('setCategoryLoading', true)
      try {
        const data = await Api.put(`program/categories/${params.id}`, params)
        commit('updateCategory', data.data)
        commit('resetCategoryFormError')
      } catch ({ response: { data } }) {
        commit('setEditCategoryErrors', data.errors)
      }
      commit('setCategoryLoading', false)
    },

    async removeProgramCategory({ commit }, params) {
      commit('setCategoryLoading', true)
      try {
        await Api.delete(`program/categories/${params.id}`, params)
        commit('removeCategory', params.id)
      } catch (data) {}
      commit('setCategoryLoading', false)
    },
  },
}
