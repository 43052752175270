import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import notification from './modules/notification'
import report from './modules/report'
import onboarding from './modules/onboarding'
import versionControl from './modules/version-control'
import exerciseCategory from './modules/exercise-category'
import exercise from './modules/exercise'
import programCategory from './modules/program-category'
import program from './modules/program'
import practitioner from './modules/practitioner'
import transaction from './modules/transaction'
import education from './modules/education'
import educationCategory from './modules/education-category'
import weeklyExercise from './modules/weekly-exercise'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    notification,
    report,
    versionControl,
    onboarding,
    exerciseCategory,
    exercise,
    programCategory,
    program,
    practitioner,
    transaction,
    education,
    educationCategory,
    weeklyExercise,
  },
  state: {
    controls: {
      showDrawer: true,
    },
    snackbar: {
      text: '',
      show: false,
      color: 'success',
    },
    uploadProgress: 0,
  },
  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    },
    setSnackbar(state, payload) {
      state.snackbar.text = payload.text || state.snackbar.text
      state.snackbar.show = payload.show || state.snackbar.show
      state.snackbar.color = payload.color || state.snackbar.color
    },
    setStateProgress(state, payload) {
      state.uploadProgress = payload
    },
  },
})
