import Api from '@/services/api'
export default {
  namespaced: true,
  state: {
    weeklyExerciseList: {
      loading: false,
      params: { page: 1, per_page: 10 },
      data: [],
    },
  },

  mutations: {
    setWeeklyExerciseList(state, payload) {
      state.weeklyExerciseList = payload
    },
  },

  actions: {
    async getWeeklyExerciseList({ commit, state }, { programId, params }) {
      commit('setWeeklyExerciseList', {
        ...state.weeklyExerciseList,
        loading: true,
      })

      const { data } = await Api.get(
        `admin/programs/${programId}/weekly-exercises?includes[]=dailyExercises`
      )

      const updatedParams = {
        ...params,
        includes: ['dailyExercises'],
      }

      commit('setWeeklyExerciseList', {
        loading: false,
        params: updatedParams,
        data: data.data,
      })
    },
  },
}
