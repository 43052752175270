import Form from '@/utils/form'
import Api from '@/services/api'
import Program from '@/models/Program'
import { each } from 'lodash'
import router from '@/routes'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    programList: {
      loading: false,
      params: { page: 1, per_page: 10 },
      data: {
        data: [],
        meta: { total: 0 },
      },
    },
    selectedProgram: {
      loading: true,
      data: null,
    },
    newDuplicatedProgram: null,
    programs: [],
    program: null,
    programForm: new Form({
      photos: [],
      category_id: null,
      title: null,
      content: null,
      price: null,
      iap_product_id: null,
      weekly_exercises: [],
      video_thumb_nail: null,
      is_free: false,
    }),
    programDuplicating: false,
    programLoading: false,
    programsLoading: false,
    programLoadUpdate: false,
    productIds: [],
    dayLoading: false,
  },

  mutations: {
    setProgramList(state, payload) {
      state.programList = payload
    },
    setSelectedProgram(state, payload) {
      state.selectedProgram = payload
    },
    setNewDuplicatedProgram(state, data) {
      state.newDuplicatedProgram = data
    },
    appendNextToProgram(state, { data, index }) {
      if (index > -1) {
        if (state.programs?.meta?.per_page) {
          var currentPage = state.programs?.meta?.current_page
          var perPage = state.programs?.meta?.per_page
          var supposedTotal = perPage * currentPage
          var totalOnCurrentPage = supposedTotal - state.programs?.data.length
          if (totalOnCurrentPage <= 0) {
            state.programs?.data?.pop()
          }
          state.programs?.data?.splice(index, 0, new Program(data))
        }
      }
    },
    setPrograms(state, payload) {
      state.programs = payload
    },
    setLoadMorePrograms(state, payload) {
      each(payload.data, (program) => {
        state.programs.data.push(new Program(program))
      })
      state.programs.meta = payload.meta
      state.programs.links = payload.links
    },
    setProductIds(state, payload) {
      state.productIds = payload.data
    },
    setProgramErrors(state, payload) {
      if (payload.thumb_nail) {
        payload['photos'] = payload.thumb_nail
      }
      state.programForm.$setErrors(payload)
    },
    setProgramLoading(state, payload) {
      state.programLoading = payload
    },
    setDayLoading(state, payload) {
      state.dayLoading = payload
    },
    setProgramDuplicating(state, payload) {
      state.programDuplicating = payload
    },
    setProgramsLoading(state, payload) {
      state.programsLoading = payload
    },
    resetProgramFormError(state) {
      state.programForm.$reset()
    },
    clearFormErrors(state) {
      state.programForm.$clearErrors()
    },
    setProgramItem(state, payload) {
      state.program = payload
      Object.assign(state.programForm, payload.data)
      state.programForm.photos = []
      state.programForm.photos.push(payload.data.thumb_nail)
      state.programForm.category_id = payload.data.category.id
    },
    updateProgram(state, payload) {
      Object.assign(state.programForm, payload.data)
      state.programForm.photos = []
      state.programForm.photos.push(payload.data.thumb_nail)
    },
    setProgramLoadUpdate(state, payload) {
      state.programLoadUpdate = payload
    },
    setUdpateProgramWeeklyExercise(state, payload) {
      const weekIndex = state.program.data.weekly_exercises.findIndex(
        (week) => week.id === payload.data.id
      )
      Vue.set(state.program.data.weekly_exercises, weekIndex, payload.data)
    },
    setDailyExercise(state, payload) {
      const weekIndex = state.program.data.weekly_exercises.findIndex(
        (week) => week.id === payload.id
      )
      if (weekIndex > -1) {
        state.program.data.weekly_exercises[weekIndex].daily_exercises.push(
          payload.data
        )
      }
    },
    removeDailyExercise(state, payload) {
      const weekIndex = state.program.data.weekly_exercises.findIndex(
        (week) => week.id === payload.week_id
      )

      const dailyExercises =
        state.program.data.weekly_exercises[weekIndex].daily_exercises

      const dayIndex = dailyExercises.findIndex(
        (day) => day.id === payload.day_id
      )

      if (weekIndex > -1) {
        state.program.data.weekly_exercises[weekIndex].daily_exercises.splice(
          dayIndex,
          1
        )
      }
    },
    clearPrograms(state, payload) {
      state.programs = {
        data: [],
      }
    },
    setWeeklyExercises(state, payload) {
      state.program.data.weekly_exercises.push(payload)
    },
    removeWeeklyExercise(state, payload) {
      const weekIndex = state.program.data.weekly_exercises.findIndex(
        (week) => week.id === payload.week_id
      )

      if (weekIndex > -1) {
        state.program.data.weekly_exercises.splice(weekIndex, 1)
      }
    },
  },

  actions: {
    async getProgramList({ commit, state }, params) {
      commit('setProgramList', { ...state.programList, loading: true })

      const query = Program.page(params?.page ?? 1).limit(
        params?.per_page ?? 10
      )

      if (params.search) {
        query.where('search', params.search)
      }

      if (params?.sort) {
        query.orderBy(params.sort.length > 0 ? params.sort : '-created_at')
      }

      const data = await query.get()

      commit('setProgramList', { loading: false, params, data })
    },

    async getProgramDetails({ commit }, programId) {
      commit('setSelectedProgram', { data: null, loading: true })
      const { data } = await Api.get(`admin/programs/${programId}`)
      commit('setSelectedProgram', { data: data.data, loading: false })
    },

    async duplicateProgramDayExercise({ commit }, { day_id }) {
      try {
        const { data } = await Api.post(`programs/day/${day_id}/duplicate`)
        commit('setProgramItem', data)
      } catch (data) {
      } finally {
      }
    },
    async duplicateProgramWeeklyExercise({ commit }, { week_id }) {
      try {
        const { data } = await Api.post(`programs/week/${week_id}/duplicate`)
        commit('setProgramItem', data)
      } catch (data) {
      } finally {
      }
    },
    setNewDuplicated({ commit }, data) {
      commit('setNewDuplicatedProgram', data)
    },
    async duplicateProgram({ commit }, { program_id, index }) {
      try {
        commit('setProgramDuplicating', true)
        const { data } = await Api.post(`programs/${program_id}/duplicate`)
        commit('appendNextToProgram', { index, data: data?.data })
        commit('setNewDuplicatedProgram', data?.data)
      } catch (data) {
      } finally {
        commit('setProgramDuplicating', false)
      }
    },
    async getPrograms({ commit }, params = { page: 1 }) {
      commit('setProgramsLoading', true)

      const query = Program.page(params.page ? params.page : 1)
      if (params.search) {
        query.where('search', params.search)
      }

      if (params.loadMore) {
        delete params.loadMore
        const res = await query.params(params).get()
        commit('setLoadMorePrograms', res)
      } else {
        const res = await query.params(params).get()
        commit('setPrograms', res)
      }

      commit('setProgramsLoading', false)
    },
    async getProductIds({ commit }, payload = {}) {
      try {
        const { data } = await Api.get(`program/product-ids`)
        commit('setProductIds', data)
      } catch ({ response: { data } }) {}
    },
    async getProgram({ commit }, payload = {}) {
      try {
        const { data } = await Api.get(`programs/${payload.id}`)
        commit('setProgramItem', data)
      } catch ({ response: { data } }) {}
    },
    async updateProgram({ commit }, params) {
      commit('setProgramLoading', true)
      try {
        const data = await Api.post(
          `programs/${params.id}?_method=put`,
          params.data
        )
        commit('updateProgram', data.data)
        commit(
          'setSnackbar',
          { show: true, text: 'Successfully updated' },
          { root: true }
        )
        commit('clearFormErrors')
      } catch ({ response: { data } }) {
        commit('setProgramErrors', data.errors)
      }
      commit('setProgramLoading', false)
    },
    async addProgramDayExercise({ commit }, params) {
      commit('setDayLoading', true)
      try {
        const { data } = await Api.post(
          `program/weekly-exercise/${params.id}/daily-exercises`,
          params.data
        )
        commit('setDailyExercise', { data: data.data, id: params.id })
      } catch ({ response: { data } }) {
        commit('setProgramErrors', data.errors)
      }
      commit('setDayLoading', false)
    },
    async addProgramWeeklyExercise({ commit }, params) {
      commit('setProgramLoading', true)
      try {
        const { data } = await Api.post(
          `program/${params.id}/weekly-exercises`,
          params.data
        )
        commit('setWeeklyExercises', data.data)
      } catch ({ response: { data } }) {
        commit('setProgramErrors', data.errors)
      }
      commit('setProgramLoading', false)
    },
    async udpateProgramWeeklyExercise({ commit }, params) {
      commit('setProgramLoadUpdate', true)
      try {
        const data = await Api.post(
          `program/${params.program_id}/weekly-exercises/${params.week_id}?_method=put`,
          params.data
        )
        commit('setUdpateProgramWeeklyExercise', data.data)
      } catch ({ response: { data } }) {
        commit('setProgramErrors', data.errors)
      }
      commit('setProgramLoadUpdate', false)
    },
    async deleteProgramWeeklyExercise({ commit }, params) {
      commit('setProgramLoading', true)
      try {
        await Api.delete(
          `program/${params.program_id}/weekly-exercises/${params.week_id}`
        )
        commit('removeWeeklyExercise', {
          week_id: params.week_id,
        })
      } catch ({ response: { data } }) {
        commit('setProgramErrors', data.errors)
      }
      commit('setProgramLoading', false)
    },
    async udpateProgramDayExercise({ commit }, params) {
      commit('setProgramLoadUpdate', true)
      try {
        await Api.post(
          `program/weekly-exercise/${params.week_id}/daily-exercises/${params.day_id}`,
          params.data
        )
      } catch ({ response: { data } }) {
        commit('setProgramErrors', data.errors)
      }
      commit('setProgramLoadUpdate', false)
    },
    async deleteProgramDayExercise({ commit }, params) {
      commit('setProgramLoadUpdate', true)
      try {
        await Api.delete(
          `program/weekly-exercise/${params.week_id}/daily-exercises/${params.day_id}`
        )
        commit('removeDailyExercise', {
          week_id: params.week_id,
          day_id: params.day_id,
        })
      } catch ({ response: { data } }) {
        commit('setProgramErrors', data.errors)
      }
      commit('setProgramLoadUpdate', false)
    },
    async createProgram({ commit }, params) {
      commit('setProgramLoading', true)
      try {
        const { data } = await Api.post(`programs`, params)
        commit(
          'setSnackbar',
          { show: true, text: 'Successfully created' },
          { root: true }
        )

        router.push({ name: 'program.item', params: { id: data.data.id } })
        commit('resetProgramFormError')
      } catch ({ response: { data } }) {
        commit('setProgramErrors', data.errors)
      }
      commit('setProgramLoading', false)
    },
    async deleteProgram({ commit }, id) {
      commit('setProgramLoading', true)

      commit(
        'setSnackbar',
        { show: true, text: 'Program deleted' },
        { root: true }
      )
      try {
        await Api.delete(`programs/${id}`)
      } catch (data) {}
      commit('setProgramLoading', false)
    },
  },
}
