import Form from '@/utils/form'
import Api from '@/services/api'
import Exercise from '@/models/Exercise'
import { each } from 'lodash'
import router from '@/routes'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    exerciseList: {
      loading: false,
      params: { page: 1, per_page: 10 },
      data: {
        data: [],
        meta: { total: 0 },
      },
    },
    selectedExercise: {
      loading: true,
      data: null,
    },
    newDuplicatedExercise: null,
    exercises: { data: [] },
    exercise: null,
    exerciseForm: new Form({
      photos: [],
      category_id: null,
      title: null,
      content: null,
      price: null,
      iap_product_id: null,
      weekly_exercises: [],
      video_thumb_nail: null,
      is_free: false,
    }),
    exerciseDuplicating: false,
    exerciseLoading: false,
    exercisesLoading: false,
    exerciseLoadUpdate: false,
    productIds: [],
    dayLoading: false,
    addAltExerciseLoading: false,
    updateAltExerciseLoading: false,
    uploadExercisesLoading: false,
  },

  getters: {
    contentExercises(state) {
      return state.exercises.data?.map((obj) => ({
        ...obj,
        exercise_video_id: obj.exercise ? obj.exercise.id : obj.id,
        number_of_sets: null,
        number_of_reps: null,
        intensity_technique: null,
        rest: null,
        tempo: null,
        alternative_exercises: [],
        super_set_label: null,
      }))
    },

    contentAltExercises(state) {
      return state.altExercises.data.map((obj) => ({
        ...obj,
        exercise_video_id: obj.exercise ? obj.exercise.id : obj.id,
        number_of_sets: null,
        number_of_reps: null,
        intensity_technique: null,
        rest: null,
        tempo: null,
        alternative_exercises: [],
        super_set_label: null,
      }))
    },
  },

  mutations: {
    setExerciseList(state, payload) {
      state.exerciseList = payload
    },
    setExerciseCategories(state, payload) {
      state.selectedExercise.categories = payload
    },
    setSelectedExercise(state, payload) {
      state.selectedExercise = payload
    },
    setNewDuplicatedExercise(state, data) {
      state.newDuplicatedExercise = data
    },
    appendNextToExercise(state, { data, index }) {
      if (index > -1) {
        if (state.exercises?.meta?.per_page) {
          var currentPage = state.exercises?.meta?.current_page
          var perPage = state.exercises?.meta?.per_page
          var supposedTotal = perPage * currentPage
          var totalOnCurrentPage = supposedTotal - state.exercises?.data.length
          if (totalOnCurrentPage <= 0) {
            state.exercises?.data?.pop()
          }
          state.exercises?.data?.splice(index, 0, new Exercise(data))
        }
      }
    },
    setExercises(state, payload) {
      state.exercises = payload
    },
    setLoadMoreExercises(state, payload) {
      each(payload.data, (exercise) => {
        state.exercises.data.push(new Exercise(exercise))
      })
      state.exercises.meta = payload.meta
      state.exercises.links = payload.links
    },
    setProductIds(state, payload) {
      state.productIds = payload.data
    },
    setExerciseErrors(state, payload) {
      if (payload.thumb_nail) {
        payload['photos'] = payload.thumb_nail
      }
      state.exerciseForm.$setErrors(payload)
    },
    setExerciseLoading(state, payload) {
      state.exerciseLoading = payload
    },
    setDayLoading(state, payload) {
      state.dayLoading = payload
    },
    setExerciseDuplicating(state, payload) {
      state.exerciseDuplicating = payload
    },
    setExercisesLoading(state, payload) {
      state.exercisesLoading = payload
    },
    resetExerciseFormError(state) {
      state.exerciseForm.$reset()
    },
    clearFormErrors(state) {
      state.exerciseForm.$clearErrors()
    },
    setExerciseItem(state, payload) {
      state.exercise = payload
      Object.assign(state.exerciseForm, payload.data)
      state.exerciseForm.photos = []
      state.exerciseForm.photos.push(payload.data.thumb_nail)
      state.exerciseForm.category_id = payload.data.category.id
    },
    updateExercise(state, payload) {
      Object.assign(state.exerciseForm, payload.data)
      state.exerciseForm.photos = []
      state.exerciseForm.photos.push(payload.data.thumb_nail)
    },
    setExerciseLoadUpdate(state, payload) {
      state.exerciseLoadUpdate = payload
    },
    setUdpateExerciseWeeklyExercise(state, payload) {
      const weekIndex = state.exercise.data.weekly_exercises.findIndex(
        (week) => week.id === payload.data.id
      )
      Vue.set(state.exercise.data.weekly_exercises, weekIndex, payload.data)
    },
    setDailyExercise(state, payload) {
      const weekIndex = state.exercise.data.weekly_exercises.findIndex(
        (week) => week.id === payload.id
      )
      if (weekIndex > -1) {
        state.exercise.data.weekly_exercises[weekIndex].daily_exercises.push(
          payload.data
        )
      }
    },
    removeDailyExercise(state, payload) {
      const weekIndex = state.exercise.data.weekly_exercises.findIndex(
        (week) => week.id === payload.week_id
      )

      const dailyExercises =
        state.exercise.data.weekly_exercises[weekIndex].daily_exercises

      const dayIndex = dailyExercises.findIndex(
        (day) => day.id === payload.day_id
      )

      if (weekIndex > -1) {
        state.exercise.data.weekly_exercises[weekIndex].daily_exercises.splice(
          dayIndex,
          1
        )
      }
    },
    clearExercises(state, payload) {
      state.exercises = {
        data: [],
      }
    },
    setWeeklyExercises(state, payload) {
      state.exercise.data.weekly_exercises.push(payload)
    },
    removeWeeklyExercise(state, payload) {
      const weekIndex = state.exercise.data.weekly_exercises.findIndex(
        (week) => week.id === payload.week_id
      )

      if (weekIndex > -1) {
        state.exercise.data.weekly_exercises.splice(weekIndex, 1)
      }
    },
    updateAltExercise(state, payload) {
      const altExerciseIndex =
        state.selectedExercise.data.alternative_exercises.findIndex(
          (altExercise) => altExercise.id === payload.data.id
        )
      Vue.set(
        state.selectedExercise.data.alternative_exercises,
        altExerciseIndex,
        payload.data
      )
    },
    setAltExercise(state, payload) {
      state.selectedExercise.data.alternative_exercises.push(payload.data)
    },
    setAddAltExerciseLoading(state, payload) {
      state.addAltExerciseLoading = payload
    },
    setUpdateAltExerciseLoading(state, payload) {
      state.updateAltExerciseLoading = payload
    },
    removeAltExercise(state, payload) {
      const altExerciseId =
        state.selectedExercise.data.alternative_exercises.findIndex(
          (altExercise) => altExercise.id === payload.exercise_id
        )

      if (altExerciseId > -1) {
        state.selectedExercise.data.alternative_exercises.splice(
          altExerciseId,
          1
        )
      }
    },
    pushExercises(state, payload) {
      each(payload.data, (exercise) => {
        state.exerciseList.data.data.unshift(new Exercise(exercise))
      })
    },
    setUploadExercisesLoading(state, payload) {
      state.uploadExercisesLoading = payload
    },
  },

  actions: {
    async getExerciseList({ commit, state }, params) {
      commit('setExerciseList', { ...state.exerciseList, loading: true })

      const query = Exercise.page(params?.page ?? 1).limit(
        params?.per_page ?? 10
      )

      if (params.search) {
        query.where('search', params.search)
      }

      if (params?.sort) {
        query.orderBy(params.sort.length > 0 ? params.sort : '-created_at')
      }

      if (params.filter) {
        query.params(params.filter)
      }

      const data = await query.get()

      commit('setExerciseList', { loading: false, params, data })
    },

    async getExerciseDetails({ commit }, exerciseId) {
      commit('setSelectedExercise', { data: null, loading: true })
      const { data } = await Api.get(`exercise/videos/${exerciseId}`)
      commit('setSelectedExercise', { data: data.data, loading: false })
    },

    async duplicateExerciseDayExercise({ commit }, { day_id }) {
      try {
        const { data } = await Api.post(`exercises/day/${day_id}/duplicate`)
        commit('setExerciseItem', data)
      } catch (data) {
      } finally {
      }
    },
    async duplicateExerciseWeeklyExercise({ commit }, { week_id }) {
      try {
        const { data } = await Api.post(`exercises/week/${week_id}/duplicate`)
        commit('setExerciseItem', data)
      } catch (data) {
      } finally {
      }
    },
    setNewDuplicated({ commit }, data) {
      commit('setNewDuplicatedExercise', data)
    },
    async duplicateExercise({ commit }, { exercise_id, index }) {
      try {
        commit('setExerciseDuplicating', true)
        const { data } = await Api.post(`exercises/${exercise_id}/duplicate`)
        commit('appendNextToExercise', { index, data: data?.data })
        commit('setNewDuplicatedExercise', data?.data)
      } catch (data) {
      } finally {
        commit('setExerciseDuplicating', false)
      }
    },
    async getExercises({ commit }, params = { page: 1 }) {
      commit('setExercisesLoading', true)

      const query = Exercise.page(params.page ? params.page : 1)
      if (params.search) {
        query.where('search', params.search)
      }

      if (params.loadMore) {
        delete params.loadMore
        const res = await query.params(params).get()
        commit('setLoadMoreExercises', res)
      } else {
        const res = await query.params(params).get()
        commit('setExercises', res)
      }

      commit('setExercisesLoading', false)
    },
    async getProductIds({ commit }, payload = {}) {
      try {
        const { data } = await Api.get(`exercise/product-ids`)
        commit('setProductIds', data)
      } catch ({ response: { data } }) {}
    },
    async getExercise({ commit }, payload = {}) {
      try {
        const { data } = await Api.get(`exercises/${payload.id}`)
        commit('setExerciseItem', data)
      } catch ({ response: { data } }) {}
    },
    async updateExercise({ commit }, params) {
      commit('setExerciseLoading', true)
      try {
        const data = await Api.post(
          `exercise/${params.id}?_method=put`,
          params.data
        )
        commit('updateExercise', data.data)
        commit(
          'setSnackbar',
          { show: true, text: 'Successfully updated' },
          { root: true }
        )
        commit('clearFormErrors')
      } catch ({ response: { data } }) {
        commit('setExerciseErrors', data.errors)
      }
      commit('setExerciseLoading', false)
    },
    async addAltExercise({ commit }, params) {
      commit('setAddAltExerciseLoading', true)
      try {
        const data = await Api.post(
          `exercise/${params.exercise_id}/alternatives`,
          params.data
        )
        commit('setAltExercise', data.data)
      } catch ({ response: { data } }) {
        commit('setExerciseErrors', data.errors)
      }
      commit('setAddAltExerciseLoading', false)
    },
    async updateAltExercise({ commit }, params) {
      commit('setUpdateAltExerciseLoading', true)
      try {
        const data = await Api.post(
          `exercise/${params.exercise_id}/alternatives/${params.alt_exercise_id}?_method=put`,
          params.data
        )
        commit('updateAltExercise', data.data)
      } catch ({ response: { data } }) {
        commit('setExerciseErrors', data.errors)
      }
      commit('setUpdateAltExerciseLoading', false)
    },
    async deleteAltExercise({ commit }, params) {
      try {
        await Api.delete(
          `exercise/${params.exercise_id}/alternatives/${params.alt_exercise_id}`
        )
        commit('removeAltExercise', { exercise_id: params.alt_exercise_id })
      } catch (data) {}
    },
    async addExerciseDayExercise({ commit }, params) {
      commit('setDayLoading', true)
      try {
        const { data } = await Api.post(
          `exercise/weekly-exercise/${params.id}/daily-exercises`,
          params.data
        )
        commit('setDailyExercise', { data: data.data, id: params.id })
      } catch ({ response: { data } }) {
        commit('setExerciseErrors', data.errors)
      }
      commit('setDayLoading', false)
    },
    async addExerciseWeeklyExercise({ commit }, params) {
      commit('setExerciseLoading', true)
      try {
        const { data } = await Api.post(
          `exercise/${params.id}/weekly-exercises`,
          params.data
        )
        commit('setWeeklyExercises', data.data)
      } catch ({ response: { data } }) {
        commit('setExerciseErrors', data.errors)
      }
      commit('setExerciseLoading', false)
    },
    async udpateExerciseWeeklyExercise({ commit }, params) {
      commit('setExerciseLoadUpdate', true)
      try {
        const data = await Api.post(
          `exercise/${params.exercise_id}/weekly-exercises/${params.week_id}?_method=put`,
          params.data
        )
        commit('setUdpateExerciseWeeklyExercise', data.data)
      } catch ({ response: { data } }) {
        commit('setExerciseErrors', data.errors)
      }
      commit('setExerciseLoadUpdate', false)
    },
    async deleteExerciseWeeklyExercise({ commit }, params) {
      commit('setExerciseLoading', true)
      try {
        await Api.delete(
          `exercise/${params.exercise_id}/weekly-exercises/${params.week_id}`
        )
        commit('removeWeeklyExercise', {
          week_id: params.week_id,
        })
      } catch ({ response: { data } }) {
        commit('setExerciseErrors', data.errors)
      }
      commit('setExerciseLoading', false)
    },
    async udpateExerciseDayExercise({ commit }, params) {
      commit('setExerciseLoadUpdate', true)
      try {
        await Api.post(
          `exercise/weekly-exercise/${params.week_id}/daily-exercises/${params.day_id}`,
          params.data
        )
      } catch ({ response: { data } }) {
        commit('setExerciseErrors', data.errors)
      }
      commit('setExerciseLoadUpdate', false)
    },
    async deleteExerciseDayExercise({ commit }, params) {
      commit('setExerciseLoadUpdate', true)
      try {
        await Api.delete(
          `exercise/weekly-exercise/${params.week_id}/daily-exercises/${params.day_id}`
        )
        commit('removeDailyExercise', {
          week_id: params.week_id,
          day_id: params.day_id,
        })
      } catch ({ response: { data } }) {
        commit('setExerciseErrors', data.errors)
      }
      commit('setExerciseLoadUpdate', false)
    },
    async createExercise({ commit }, params) {
      commit('setExerciseLoading', true)
      try {
        const { data } = await Api.post(`exercises`, params)
        commit(
          'setSnackbar',
          { show: true, text: 'Successfully created' },
          { root: true }
        )

        router.push({ name: 'exercise.item', params: { id: data.data.id } })
        commit('resetExerciseFormError')
      } catch ({ response: { data } }) {
        commit('setExerciseErrors', data.errors)
      }
      commit('setExerciseLoading', false)
    },
    async deleteExercise({ commit }, id) {
      commit('setExerciseLoading', true)

      commit(
        'setSnackbar',
        { show: true, text: 'Exercise deleted' },
        { root: true }
      )
      try {
        await Api.delete(`exercises/${id}`)
      } catch (data) {}
      commit('setExerciseLoading', false)
    },
    async uploadExercises({ commit, dispatch }, params) {
      commit('setUploadExercisesLoading', true)
      try {
        await Api.post(`exercise/bulk-batch-upload`, params)
        await dispatch('getExerciseList', { page: 1 })
        commit('resetExerciseFormError')
        commit(
          'setSnackbar',
          { show: true, text: 'Successfully created' },
          { root: true }
        )
      } catch ({ response: { data } }) {
        commit(
          'setSnackbar',
          { show: true, text: data.errors.file[0], color: 'error' },
          { root: true }
        )
        commit('setExerciseErrors', data.errors)
      }
      commit('setUploadExercisesLoading', false)
    },
  },
}
