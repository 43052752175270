import ExerciseCategory from '@/models/ExerciseCategory'
import Category from '@/models/Category'
import Api from '@/services/api'
import { each, find } from 'lodash'
import Form from '@/utils/form'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    categoryList: {
      loading: false,
      params: { page: 1, per_page: 10 },
      data: {
        data: [],
        meta: { total: 0 },
      },
    },
    categories: [],
    categoryForm: new Form({
      label: null,
      id: null,
      type: null,
      sub_categories: [],
      has_error: false,
    }),
    categoryLoading: false,
    editCategoryValue: new Form({
      label: null,
      id: null,
      type: null,
      sub_categories: [],
      has_error: false,
    }),
  },

  mutations: {
    setCategoryList(state, payload) {
      state.categoryList = payload
    },
    setCategories(state, categories) {
      each(categories, (category) => {
        const exist = find(state.categoryList.data.data, { id: category.id })
        if (!exist) {
          state.categoryList.data.data.push(new ExerciseCategory(category))
        }
      })
      each(categories, (category) => {
        const exist = find(state.categories, { id: category.id })
        if (!exist) {
          state.categories.push(new ExerciseCategory(category))
        }
      })
    },
    updateEditCategory(state, category) {
      Object.keys(category).forEach(function (key) {
        if (state.editCategoryValue.hasOwnProperty(key)) {
          state.editCategoryValue[key] = category[key]
        }
      })

      state.editCategoryValue.sub_categories.map((item, key) => {
        const data = {
          label: null,
        }

        const lastEl = item.sub_sub_categories.length - 1
        if (
          item.sub_sub_categories[lastEl] &&
          item.sub_sub_categories[lastEl].label &&
          item.sub_sub_categories[lastEl].label !== ''
        ) {
          item.sub_sub_categories.push(data)
        }
      })
    },
    updateCategory(state, data) {
      const catIndex = state.categoryList.data.data.findIndex(
        (category) => category.id === data.data.id
      )
      Vue.set(state.categoryList.data.data, catIndex, data.data)
    },
    setCategoryLoading(state, payload) {
      state.categoryLoading = payload
    },
    removeCategory(state, id) {
      // find the category in categories
      const catIndex = state.categoryList.data.data.findIndex(
        (category) => category.id === id
      )
      if (catIndex > -1) {
        state.categoryList.data.data.splice(catIndex, 1)
      } else {
        // find the category in sub categories
        const subCatIndex = state.editCategoryValue.sub_categories.findIndex(
          (category) => category.id === id
        )

        if (subCatIndex > -1) {
          if (state.editCategoryValue.id) {
            state.editCategoryValue.sub_categories.splice(subCatIndex, 1)
          }
        } else {
          // find the category in sub sub categories
          state.editCategoryValue.sub_categories.map((subCategory, subKey) => {
            subCategory.sub_sub_categories.map((subSubCategory, subSubKey) => {
              if (id === subSubCategory.id) {
                if (state.editCategoryValue.id) {
                  subCategory.sub_sub_categories.splice(subSubKey, 1)
                }
              }
            })
          })
        }
      }
    },
    addSubCategory(state) {
      const data = {
        label: null,
        sub_sub_categories: [
          {
            label: null,
          },
        ],
      }

      let form = state.categoryForm.sub_categories

      if (state.editCategoryValue.id) {
        form = state.editCategoryValue.sub_categories
      }

      form.push(data)
    },
    addSubSubCategory(state, subCategoryKey) {
      const data = {
        label: null,
      }

      let form = state.categoryForm.sub_categories[subCategoryKey]

      if (state.editCategoryValue.id) {
        form = state.editCategoryValue.sub_categories[subCategoryKey]
      }

      form.sub_sub_categories.push(data)
    },
    deleteSubSubCategory(state, subs) {
      let subCategory = state.categoryForm.sub_categories[subs.subKey]

      if (state.editCategoryValue.id) {
        subCategory = state.editCategoryValue.sub_categories[subs.subKey]
      }

      subCategory.sub_sub_categories.splice(subs.subSubKey, 1)
    },
    deleteSubCategory(state, subKey) {
      let subCategory = state.categoryForm.sub_categories

      if (state.editCategoryValue.id) {
        subCategory = state.editCategoryValue.sub_categories
      }

      subCategory.splice(subKey, 1)
    },
    setExerciseCategoryErrors(state, payload) {
      state.categoryForm.has_error = !!Object.keys(payload).length
      state.categoryForm.$setErrors(payload)
    },
    setEditCategoryErrors(state, payload) {
      state.editCategoryValue.has_error = !!Object.keys(payload).length
      state.editCategoryValue.$setErrors(payload)
    },
    resetCategoryFormError(state) {
      state.categoryForm.$reset()
      state.editCategoryValue.$reset()
      state.categoryForm.has_error = false
      state.editCategoryValue.has_error = false
    },
  },

  actions: {
    async getExerciseCategoryList({ commit, state }, params) {
      commit('setCategoryList', { ...state.categoryList, loading: true })

      const query = Category.where('type', 'exercise')
        .include('subcategories', 'subcategories.subcategories')
        .page(params?.page ?? 1)
        .limit(params?.per_page ?? 10)

      if (params.search) {
        query.where('search', params.search)
      }

      if (params?.sort) {
        query.orderBy(params.sort)
      }

      const data = await query.get()

      commit('setCategoryList', { loading: false, params, data })
    },
    async getExerciseCategories({ commit }, params = { page: 1, sort: 'id' }) {
      const query = ExerciseCategory.page(params.page || 1)
      if (params.search) {
        query.where('search', params.search)
      }
      const res = await query.params({ limit: 20 }).get()
      commit('setCategories', res.data)
    },

    async addExerciseCategory({ commit }, params) {
      commit('setCategoryLoading', true)
      try {
        const data = await Api.post(`exercise/categories`, params)
        commit('setCategories', [data.data.data])
        commit('resetCategoryFormError')
      } catch ({ response: { data } }) {
        commit('setExerciseCategoryErrors', data.errors)
      }
      commit('setCategoryLoading', false)
    },

    async editExerciseCategory({ commit }, params) {
      commit('setCategoryLoading', true)
      try {
        const data = await Api.put(`exercise/categories/${params.id}`, params)
        commit('updateCategory', data.data)
        commit('resetCategoryFormError')
      } catch ({ response: { data } }) {
        commit('setEditCategoryErrors', data.errors)
      }
      commit('setCategoryLoading', false)
    },

    async removeExerciseCategory({ commit }, params) {
      commit('setCategoryLoading', true)
      try {
        await Api.delete(`exercise/categories/${params.id}`, params)
        commit('removeCategory', params.id)
      } catch (data) {}
      commit('setCategoryLoading', false)
    },
  },
}
